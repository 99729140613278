.footer {
  background-color: #000000;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.footerSections {
  width: 70% !important;
  margin: 0 auto !important;
}

.footerItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}

.footerItem h3 {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0.5em 0;
}

.footerList {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footerItem ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.footerItem ul li a {
  color: white;
}

.footerTitle {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0.5em 0;
  color: white;
}

.footerText {
  color: lightgray;
  font-size: 1em;
  margin: 0.5em 0;
}

.footerBottom {
  margin-bottom: 1em;
  text-align: center;
}

.footerBottom img {
  pointer-events: none;
  user-select: none;
}
