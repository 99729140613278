.section {
  padding-top: 80px;
  padding-bottom: 130px;
  margin: 0;
  min-width: 0;
  background-color: #f9fafc;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
}

.heading {
  max-width: none;
  margin-top: 0;
  margin-bottom: 120px !important;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.heading h2 {
  font-size: 36px;
  line-height: 1.48;
  font-weight: 700;
  font-family: B612, sans-serif;
  letter-spacing: -1px;
  margin-right: 20px;
  margin-left: 20px;
}
.heading p {
  font-size: 16px;
  line-height: 2.2;
  max-width: 700px;
  margin: 10px auto 0;
}

.contentWrapper {
  display: grid;
  gap: 30px;
  margin: 0;
  min-width: 0;
  justify-content: unset;
  grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 1199px) {
  .section {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .heading {
    margin-bottom: 60px !important;
  }
}

@media (max-width: 767px) {
  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .heading {
    margin-bottom: 30px !important;
  }
  .heading h2 {
    font-size: 24px;
    letter-spacing: -0.5px;
  }
  .heading p {
    max-width: 85%;
  }
  .contentWrapper {
    justify-content: center;
    grid-template-columns: repeat(1, 280px);
  }
}
