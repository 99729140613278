.header {
  background: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
}

.item {
  margin-right: 25px;
  text-align: center;
  text-decoration: none;
}

.link {
  font-size: 1.1em !important;
  color: white !important;
  font-weight: 600 !important;
  padding-top: 10px;
}

.link span {
  margin-right: 10px;
}

.toggler {
  border-color: rgb(255, 255, 255) !important;
}

.togglerIcon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3em;
}

.arrowIcon {
  margin-left: 0.5em;
}

.buttonWrapper {
  text-align: center;
  margin: 0;
  min-width: 0;
}
.buttonWrapper button {
  background-color: #6F25D2;
  cursor: pointer;
  min-height: 50px;
  padding: 0 30px;
  margin: 0;
  border: 0;
  color: white;
  white-space: nowrap;
  transition: 0.3s ease-in-out 0s;
  border-radius: 5px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
}
.buttonWrapper button:hover {
  background-color: #4b188d;
}

@media (max-width: 991px) {
  .item {
    margin-right: 0px;
  }

  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
