.faq {
  margin: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faq .faqQuestion {
  position: relative;
  font-size: 20px;
  padding-right: 80px;

  transition: all 0.4s ease;
}

.faq .faqQuestion::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;

  background-image: url("../../public/images/assets/arrow-down-mint.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  transition: all 0.4s ease-out;
}

.faq .faqAnswer {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.4s ease-out;
}

.faq.open .faqQuestion {
  margin-bottom: 15px;
}

.faq.open .faqQuestion::after {
  transform: translateY(-50%) rotate(180deg);
}

.faq.open .faqAnswer {
  max-height: 1000px;
  opacity: 1;
}
