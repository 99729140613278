.serviceItem {
  display: flex;
  text-align: left;
  margin: 0;
  min-width: 0;
}

.figure {
  min-width: 88px;
  margin-right: 30px;
  margin-bottom: 0;
}
.figure img {
  pointer-events: none;
  user-select: none;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.content {
  margin: 0;
  min-width: 0;
}
.content h3 {
  color: #6F25D2;
  font-size: 24px;
  font-weight: 900;
  line-height: 1.68;
}
.content p {
  font-size: 14px;
  line-height: 1.88;
  margin: 0;
}

.service h3 {
  color: black;
  font-size: 22px;
  font-weight: 900;
  line-height: 1.68;
}

@media (max-width: 1399px) {
  .figure {
    min-width: 70px;
  }
}

@media (max-width: 1199px) {
  .serviceItem {
    display: block;
    text-align: center;
  }
  .figure {
    min-width: 88px;
    margin: 0;
    margin-bottom: 10px;
  }
  .figure img {
    max-width: 70px;
  }
}

@media (max-width: 991px) {
  .figure img {
    max-width: 52px;
  }
  .content h3 {
    font-size: 22px;
  }
  .content p {
    font-size: 15px;
  }
}
