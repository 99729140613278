body {
  font-family: "DM Sans", sans-serif !important;
}

a {
  text-decoration: none;
}

.strong {
  font-weight: 700;
}

.MuiFormHelperText-root {
  font-size: 0.875em !important;
  color: #dc3545;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  letter-spacing: normal !important;
  text-align: start !important;
  margin-top: 4px !important;
  margin-right: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
}

.MuiInputBase-root {
  width: 100%;
}

.MuiFormControl-root {
  display: block !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(45deg, #27ff90, #00ba5a);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 13px 1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 3px 13px 1px;
}

.fixed-top {
  z-index: 997 !important;
}
